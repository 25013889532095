/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import "./layout.css"
import 'typeface-crimson-text'
import 'typeface-roboto'
import { Navigation } from "../components/general/Navigation/Navigation";
import { SideNavigation } from "../components/general/SideNavigation/SideNavigation";

const Layout = ({ navColor = undefined, borderColor = undefined, children }) => {
  return (
    <div>
      <Navigation links={[
        {
          value: "Ons werk",
          path: "a"
        },
        {
          value: "Over ons",
          path: "a"
        }
      ]} color={navColor} borderColor={borderColor} />
      <SideNavigation />
      {children}
    </div>
  )
}

export default Layout
