import styled from "styled-components/macro"
import TransitionLink from "gatsby-plugin-transition-link"

interface SideNavigationContainerElementProps {
  open: boolean
}

export const SideNavigationContainerElement = styled.div<
  SideNavigationContainerElementProps
>`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: fixed;
  left: 0;
  width: 80px;
  z-index: 999;
  align-items: center;
  top: 0;
  justify-content: center;
  @media (max-width: 768px) {
    background: #EDF1F4 !important;
    width: 100%;
    display: ${props => (props.open ? "flex" : "none")};
  }
`

export const RotatedLinksElement = styled.div`
  @media (min-width: 769px) {
    transform: rotate(90deg);
    margin-top: 50%;
    padding-left: 60px;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    display: flex;
  }
`

export const LinkElement = styled.div`
  margin-right: 25px;
  display: inline;
`

export const LinkContainerElement = styled.div`
  max-height: 450px;
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const SubLinkContainerElement = styled.div`
  background: #edf1f4;
  height: 100vh;
  position: fixed;
  width: 0;
  left: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 768px) {
    display: none;
  }
`

export const SubLinksElement = styled.div`
  opacity: 0;
  padding: 25px;
`

export const SubLinkElement = styled.div`
  margin-bottom: 15px;
`

export const MobileSubLinksElement = styled.div`
  padding: 15px;
  @media (min-width: 769px) {
    display: none;
  }
`

export const MobileSubLinkElement = styled.div`
  margin-top: 8px;
  margin-bottom: 8px;
  padding-left: 15px;
`

export const HamburgerElement = styled.img`
  position: fixed;
  right: 0;
  top: 0;
  width: 30px;
  height: 30px;
  margin: 15px;
  z-index: 9999;
  @media (min-width: 769px) {
    display: none;
  }
`
