import styled from "styled-components/macro";
import { Color } from "../../../data/style/variables";

export const NavigationContainerElement = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  position: absolute;
  height: 80px;
  top: 0;
  left: 0;
  z-index: 999;
  background: transparent;
`;

type LinkElementProps = {
  borderColor?: Color
}

export const LinkElement = styled.div<LinkElementProps>`
  display: flex;
  width: 33.333%;
  padding-left: 25px;
  align-items: center;
  border-left: 1px solid ${props => props.borderColor ? props.borderColor : '#d6d9dc'};
  @media (max-width: 768px) {
    border-left: none;
  }
  &:first-child{
    width: calc(33.333% + 1px);
  }
  &:last-child{
    border-right: 1px solid ${props => props.borderColor ? props.borderColor : '#d6d9dc'};
  }
`;

export const LinksContainerElement = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
`;
