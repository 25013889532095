import styled from "styled-components/macro";
import { Color } from '../../../data/style/variables';

export const ContainerElement = styled.div`
  display: inline-flex;
  position: relative;
  opacity: 0;
  overflow: hidden;
`;

type BlockAnimationElementProps = {
  background: Color;
}

export const BlockAnimationElement = styled.div<BlockAnimationElementProps>`
  width: 100%;
  left: -100%;
  position: absolute;
  background: ${props => props.background};
  /* Minus 1px fixes a odd bug where text is underlined by a line that is 1px high when animation is playing. */
  height: calc(100% - 1px);
`;

export const HolderElement = styled.div`
  opacity: 0;
`;
