import { Expo, TimelineLite } from "gsap"
import { CSSPlugin } from "gsap/CSSPlugin"
import React, { useContext, useEffect, useRef } from "react"
import { Color } from "../../../data/style/variables"
import { PageContext } from "../../context/PageManager/PageManager"
import {
  BlockAnimationElement,
  ContainerElement,
  HolderElement,
} from "./styled"

//without this line, CSSPlugin may get dropped by your bundler...
const plugins = [CSSPlugin]

type RevealBlockAnimationProps = {
  background: Color
  reverseOn?: boolean
  index?: number
}

export const RevealBlockAnimation: React.FC<RevealBlockAnimationProps> = ({
  background,
  reverseOn,
  index,
  children,
}) => {
  const pageContext = useContext(PageContext)
  const blockAnimationElementRef = useRef<HTMLDivElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const holderRef = useRef<HTMLDivElement>(null)
  const timeline = new TimelineLite({ paused: true })
  const options = {
    threshold: 0.5,
  }

  const obeserverCallback = (entries: IntersectionObserverEntry[]) => {
    entries.forEach(entry => {
      if (entry.isIntersecting && pageContext.pageLoaded) {
        window.setTimeout(() => timeline.play(), index ? index * 0.4 * 1000 : 0)
      } else if (reverseOn) {
        timeline.reverse()
      }
    })
  }

  useEffect(() => {
    const observer = new IntersectionObserver(obeserverCallback, options)

    if (
      blockAnimationElementRef.current &&
      containerRef.current &&
      holderRef.current
    ) {
      timeline
        .to(
          blockAnimationElementRef.current,
          0.6,
          {
            left: "100%",
            opacity: 1,
            ease: Expo.easeOut,
          },
          0.3
        )
        .to(
          holderRef.current,
          0.6,
          {
            opacity: 1,
            ease: Expo.easeOut,
          },
          0.6
        )
        .to(
          containerRef.current,
          0.2,
          {
            opacity: 1,
            ease: Expo.easeOut,
          },
          0
        )
    }

    if (containerRef.current) observer.observe(containerRef.current)

    // Remove the observer when component leaves scope,
    // this fixes repeating animations when switching or going back in browsers.
    return () => {
      observer.disconnect()
    }
  }, [pageContext.pageLoaded])

  return (
    <ContainerElement ref={containerRef}>
      <BlockAnimationElement
        background={background}
        ref={blockAnimationElementRef}
      />
      <HolderElement ref={holderRef}>{children}</HolderElement>
    </ContainerElement>
  )
}
