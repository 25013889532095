export enum FontFamily {
  Roboto = '"Roboto", sans-serif',
  RobotoMono = '"Roboto Mono", sans-serif',
  RobotoSlab = '"Roboto Slab", serif',
  FiraCode = '"Fira Code", monospace',
  CrimsonText = '"Crimson Text", sans-serif',
}

export enum Color {
  Primary = "#0745af",
  // Primary = "#00009E",
  PrimaryOpague = "#85a8e2",
  Secondary = "#666666",
  PrimaryLight = "#ffffff",
  SecondaryLight = "#B0C3E5",
  MainGray = "#EDF1F4",
  MainBlue = "#0745af",
  // MainBlue = "#00009E",
  MainDarkBlue = "#10377A",
  // MainDarkBlue = "#00009E",
  DarkGray = "#5d5d5d",
  LightGray = "#cfcfcf",
  Black = "#000",
  BlackOpague60 = "rgba(0,0,0,.60)",
  White = "#fff",
  DarkBlue = "#00009E",
  Yellow = "#FFD23F",
  WhiteOpague = "rgba(255, 255, 255, 0.3)",
  Red = "red",
  Test1 = Yellow,
  Test2 = "#e5e5e5",
  Test3 = "#ffffff",
  DarkYellow = "rgb(214 174 41)"
}

export enum FontWeight {
  Light = 300,
  Regular = 400,
  SemiBold = 600,
  Bold = 700,
  Black = 800,
}

export enum FontSize {
  Large = "Large",
  Normal = "Normal",
  Small = "Small",
}

export enum Breakpoints {
  H1500 = "1500px",
  H1250 = "1250px",
  H1000 = "1000px",
  H750 = "750px",
  H500 = "500px",
  H250 = "250px",
}

export type Theme = {
  background?: Color | string
  color?: Color
  lineColor?: Color | string
}

export const BlueGridTheme:Theme = {
  background: Color.MainBlue,
  color: Color.PrimaryLight,
  lineColor: "#2D61BB",
}

export const GrayGridTheme:Theme = {
  background: Color.MainGray,
  color: Color.Black,
  lineColor: "#D6D9DC",
}

export const GrayGridThemeBlackText:Theme = {
  background: Color.MainGray,
  color: Color.Black,
  lineColor: "#D6D9DC",
}

export const HeroGridTheme:Theme = {
  lineColor: "rgba(255,255,255,.1)",
}

export const DarkBlueGridTheme:Theme = {
  background: Color.MainDarkBlue,
  color: Color.White,
  lineColor: "#3C5D93",
}

export const WhiteGridTheme:Theme = {
  background: "#fff",
  color: Color.Primary,
  lineColor: "#eaeaea",
}

export const YellowGridTheme:Theme = {
  background: "#FFD23F",
  color: Color.Black,
  lineColor: "rgba(0,0,0,.1)",
}

export const BlackGridTheme:Theme = {
  background: Color.Black,
  color: Color.White,
  lineColor: "rgba(255,255,255,.1)",
}
