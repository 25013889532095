interface IRoutePath {
  [state: string]: any;
}

export const RoutePath: IRoutePath = {
  Home: "/",
  Checklist: "/checklist",
  PowerBi: "/power-bi",
  Cad: "/cad",
  Erp: "/erp",
  WebAndApps: "/websites-and-apps",
  Cloud: "/cloud",
  Custom: "/custom",
  TimeManagement: "/time-management",
  NedAir: "/ned-air",
  Brink: "/brink",
  RoofFanSelector: "/roof-fan-selector",
  TechnicalSoftware: "/technische-software"
};
