import React from "react"
import { Color, FontFamily, FontWeight } from "../../../data/style/variables"
import {
  AElement,
  H1Element,
  H2Element,
  H3Element,
  PElement,
  H4Element,
  Span,
} from "./styled"

export enum TypographyTypes {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  a,
  span,
}

type TypographyProps = {
  titleType: TypographyTypes
  fontFamily?: FontFamily
  fontWeight?: FontWeight
  fontSize?: string
  color?: Color
  lineHeight?: string
  noMargin?: boolean
  underlined?: boolean
  fontSizeMobile?: string
  lineHeightMobile?: string
}

export const Typography: React.FC<TypographyProps> = ({
  titleType,
  fontFamily,
  fontWeight,
  fontSize,
  color,
  lineHeight,
  children,
  noMargin,
  underlined,
  lineHeightMobile,
  fontSizeMobile,
  ...rest
}) => {
  return (
    <>
      {titleType === TypographyTypes.h1 && (
        <H1Element
          fontFamily={fontFamily}
          fontWeight={fontWeight}
          fontSize={fontSize}
          fontSizeMobile={fontSizeMobile}
          lineHeightMobile={lineHeightMobile}
          lineHeight={lineHeight}
          color={color}
          noMargin={noMargin}
        >
          {children}
        </H1Element>
      )}
      {titleType === TypographyTypes.h2 && (
        <H2Element
          fontFamily={fontFamily}
          fontWeight={fontWeight}
          fontSize={fontSize}
          fontSizeMobile={fontSizeMobile}
          lineHeightMobile={lineHeightMobile}
          lineHeight={lineHeight}
          color={color}
          noMargin={noMargin}
          underlined={underlined}
        >
          {children}
        </H2Element>
      )}
      {titleType === TypographyTypes.h3 && (
        <H3Element
          fontFamily={fontFamily}
          fontWeight={fontWeight}
          fontSize={fontSize}
          fontSizeMobile={fontSizeMobile}
          lineHeightMobile={lineHeightMobile}
          lineHeight={lineHeight}
          color={color}
          noMargin={noMargin}
        >
          {children}
        </H3Element>
      )}
      {titleType === TypographyTypes.h4 && (
        <H4Element
          fontFamily={fontFamily}
          fontWeight={fontWeight}
          fontSize={fontSize}
          lineHeight={lineHeight}
          color={color}
          noMargin={noMargin}
        >
          {children}
        </H4Element>
      )}
      {titleType === TypographyTypes.p && (
        <PElement
          fontFamily={fontFamily}
          fontWeight={fontWeight}
          fontSize={fontSize}
          lineHeight={lineHeight}
          color={color}
          noMargin={noMargin}
        >
          {children}
        </PElement>
      )}
      {titleType === TypographyTypes.a && (
        <AElement
          fontFamily={fontFamily}
          fontWeight={fontWeight}
          fontSize={fontSize}
          lineHeight={lineHeight}
          color={color}
          noMargin={noMargin}
          {...rest}
        >
          {children}
        </AElement>
      )}
      {titleType === TypographyTypes.span && (
        <Span
          fontFamily={fontFamily}
          fontWeight={fontWeight}
          fontSize={fontSize}
          lineHeight={lineHeight}
          color={color}
          noMargin={noMargin}
        >
          {children}
        </Span>
      )}
    </>
  )
}

type SectionTextProps = {
  color?: Color
  fontWeight?: FontWeight
  fontSize?: string
  fontFamily?: FontFamily
  underlined?: boolean
}

export const PageTitle: React.FC<SectionTextProps> = ({ color, children }) => {
  return (
    <Typography
      titleType={TypographyTypes.h1}
      fontFamily={FontFamily.Roboto}
      fontWeight={FontWeight.Bold}
      fontSize={"9rem"}
      fontSizeMobile={"4.5rem"}
      lineHeight={"13.5rem"}
      lineHeightMobile={"normal"}
      color={color}
    >
      {children}
    </Typography>
  )
}

export const InfoSpan: React.FC<SectionTextProps> = ({ color, children }) => {
  return (
    <Typography
      titleType={TypographyTypes.span}
      fontFamily={FontFamily.RobotoMono}
      fontWeight={FontWeight.Regular}
      fontSize={"1.6rem"}
      lineHeight={"2.4rem"}
      color={color}
    >
      {children}
    </Typography>
  )
}

export const ItalicInfoSpan: React.FC<SectionTextProps> = ({
  color,
  children,
}) => {
  return (
    <Typography
      titleType={TypographyTypes.span}
      fontFamily={FontFamily.Roboto}
      fontWeight={FontWeight.Regular}
      fontSize={"1.6rem"}
      lineHeight={"2.4rem"}
      color={color}
    >
      <i>{children}</i>
    </Typography>
  )
}

export const PageTitleSmall: React.FC<SectionTextProps> = ({
  color,
  children,
}) => {
  return (
    <Typography
      titleType={TypographyTypes.h1}
      fontFamily={FontFamily.Roboto}
      fontWeight={FontWeight.Bold}
      fontSize={"6.2rem"}
      fontSizeMobile={"4.5rem"}
      lineHeight={"9.3rem"}
      lineHeightMobile={"normal"}
      color={color}
    >
      {children}
    </Typography>
  )
}

export const SectionTitle: React.FC<SectionTextProps> = ({
  color,
  children,
}) => {
  return (
    <Typography
      titleType={TypographyTypes.h2}
      fontFamily={FontFamily.Roboto}
      fontWeight={FontWeight.Bold}
      color={color}
    >
      {children}
    </Typography>
  )
}

export const SectionParagraphTitle: React.FC<SectionTextProps> = ({
  color,
  children,
}) => {
  return (
    <Typography
      titleType={TypographyTypes.h3}
      fontFamily={FontFamily.Roboto}
      fontWeight={FontWeight.Regular}
      color={color}
    >
      {children}
    </Typography>
  )
}

export const SectionParagraph: React.FC<SectionTextProps> = ({
  color,
  children,
  fontWeight,
  fontSize,
}) => {
  return (
    <Typography
      titleType={TypographyTypes.p}
      fontFamily={FontFamily.Roboto}
      fontWeight={fontWeight ? fontWeight : FontWeight.Regular}
      fontSize={fontSize}
      color={color}
    >
      {children}
    </Typography>
  )
}

export const BoldParagraphTitle: React.FC<SectionTextProps> = ({
  color,
  children,
}) => {
  return (
    <Typography
      titleType={TypographyTypes.h3}
      fontFamily={FontFamily.Roboto}
      fontWeight={FontWeight.Bold}
      color={color}
    >
      {children}
    </Typography>
  )
}

export const SectionParagraphLarge: React.FC<SectionTextProps> = ({
  color,
  children,
}) => {
  return (
    <Typography
      titleType={TypographyTypes.p}
      fontSize={"2.4rem"}
      fontFamily={FontFamily.Roboto}
      fontWeight={FontWeight.Light}
      color={color}
    >
      {children}
    </Typography>
  )
}

export const SectionInfoLine: React.FC<SectionTextProps> = ({
  color,
  children,
}) => {
  return (
    <Typography
      titleType={TypographyTypes.p}
      fontSize={"1.8rem"}
      fontFamily={FontFamily.RobotoMono}
      fontWeight={FontWeight.Light}
      color={color}
    >
      {children}
    </Typography>
  )
}

export const TitleH1: React.FC<SectionTextProps> = ({ color, children }) => {
  return (
    <Typography
      titleType={TypographyTypes.h1}
      fontFamily={FontFamily.Roboto}
      fontWeight={FontWeight.Bold}
      fontSize={"9rem"}
      fontSizeMobile={"4.5rem"}
      lineHeight={"normal"}
      lineHeightMobile={"normal"}
      color={color}
    >
      {children}
    </Typography>
  )
}

export const TitleH2: React.FC<SectionTextProps> = ({
  color,
  children,
  fontFamily,
  fontWeight,
  underlined,
}) => {
  return (
    <Typography
      titleType={TypographyTypes.h2}
      fontFamily={fontFamily ? fontFamily : FontFamily.Roboto}
      fontWeight={fontWeight ? fontWeight : FontWeight.Regular}
      fontSize={"6rem"}
      fontSizeMobile={"2.5rem"}
      lineHeight={"9rem"}
      lineHeightMobile={"normal"}
      underlined={underlined}
      color={color}
    >
      {children}
    </Typography>
  )
}

export const TitleH2Article: React.FC<SectionTextProps> = ({
  color,
  children,
  fontFamily,
  fontWeight,
}) => {
  return (
    <Typography
      titleType={TypographyTypes.h2}
      fontFamily={fontFamily ? fontFamily : FontFamily.Roboto}
      fontWeight={fontWeight ? fontWeight : FontWeight.Regular}
      fontSize={"4.5rem"}
      fontSizeMobile={"2.5rem"}
      lineHeight={"6.75rem"}
      lineHeightMobile={"normal"}
      color={color}
    >
      {children}
    </Typography>
  )
}

export const TitleQuote: React.FC<SectionTextProps> = ({
  color,
  children,
  fontFamily,
}) => {
  return (
    <Typography
      titleType={TypographyTypes.h2}
      fontFamily={fontFamily ? fontFamily : FontFamily.Roboto}
      fontWeight={FontWeight.Regular}
      fontSize={"3.5rem"}
      fontSizeMobile={"2rem"}
      lineHeight={"5.25rem"}
      lineHeightMobile={"normal"}
      color={color}
    >
      {children}
    </Typography>
  )
}

export const CrimsonH2: React.FC<SectionTextProps> = ({ color, children }) => {
  return (
    <Typography
      titleType={TypographyTypes.h2}
      fontFamily={FontFamily.CrimsonText}
      fontWeight={FontWeight.SemiBold}
      fontSize={"9rem"}
      fontSizeMobile={"4.5rem"}
      lineHeight={"13.5rem"}
      lineHeightMobile={"normal"}
      color={color}
    >
      {children}
    </Typography>
  )
}

export const LargeSectionText: React.FC<SectionTextProps> = ({
  color,
  children,
  fontFamily,
}) => {
  return (
    <Typography
      titleType={TypographyTypes.p}
      fontFamily={fontFamily ? fontFamily : FontFamily.Roboto}
      fontWeight={FontWeight.Regular}
      fontSize={"6rem"}
      fontSizeMobile={"2.5rem"}
      lineHeight={"9rem"}
      lineHeightMobile={"normal"}
      color={color}
    >
      {children}
    </Typography>
  )
}

export const TitleH3: React.FC<SectionTextProps> = ({ color, children }) => {
  return (
    <Typography
      titleType={TypographyTypes.h3}
      fontFamily={FontFamily.Roboto}
      fontWeight={FontWeight.Regular}
      fontSize={"2.3rem"}
      fontSizeMobile={"2rem"}
      lineHeight={"normal"}
      lineHeightMobile={"normal"}
      color={color}
    >
      {children}
    </Typography>
  )
}

export const Subtitle: React.FC<SectionTextProps> = ({ color, children }) => {
  return (
    <Typography
      titleType={TypographyTypes.h4}
      fontFamily={FontFamily.Roboto}
      fontWeight={FontWeight.Regular}
      fontSize={"2rem"}
      lineHeight={"3rem"}
      color={color}
    >
      {children}
    </Typography>
  )
}

export const MonoSubtitle: React.FC<SectionTextProps> = ({
  color,
  children,
}) => {
  return (
    <Typography
      titleType={TypographyTypes.h2}
      fontFamily={FontFamily.RobotoMono}
      fontWeight={FontWeight.Regular}
      fontSize={"2rem"}
      lineHeight={"3rem"}
      color={color}
    >
      {children}
    </Typography>
  )
}

export const Paragraph: React.FC<SectionTextProps> = ({
  color,
  children,
  fontWeight,
  fontFamily,
  fontSize,
}) => {
  return (
    <Typography
      titleType={TypographyTypes.p}
      fontWeight={fontWeight ? fontWeight : FontWeight.Regular}
      fontFamily={fontFamily || FontFamily.Roboto}
      fontSize={fontSize}
      color={color}
    >
      {children}
    </Typography>
  )
}

export const Anchor: React.FC<SectionTextProps> = ({
  color,
  children,
  fontWeight,
  fontSize,
}) => {
  return (
    <Typography
      titleType={TypographyTypes.p}
      fontFamily={FontFamily.Roboto}
      fontWeight={fontWeight ? fontWeight : FontWeight.Regular}
      fontSize={fontSize}
      color={color}
    >
      {children}
    </Typography>
  )
}
