import React from "react"
import { Color, FontWeight } from "../../../data/style/variables"
import { Typography, TypographyTypes } from "../Typography/Typography"
import TransitionLink from "gatsby-plugin-transition-link"
import { RoutePath } from "../../../data/RoutePath"
import {
  LinkElement,
  LinksContainerElement,
  NavigationContainerElement,
} from "./styled"

export type NavigationProps = {
  links: LinkType[]
  color?: Color
  borderColor?: Color
}

export type LinkType = {
  value: string
  path: string
}

export const Navigation: React.FC<NavigationProps> = ({
  borderColor,
  links,
  color,
}) => {
  return (
    <NavigationContainerElement>
      <LinksContainerElement>
        <LinkElement borderColor={borderColor} onClick={() => {}}>
          <TransitionLink
            to={RoutePath.Home}
            exit={{ length: 1 }}
            entry={{ delay: 1 }}
          >
            <Typography
              titleType={TypographyTypes.a}
              color={color ? color : Color.Black}
              fontWeight={FontWeight.Bold}
              fontSize={"2.5rem"}
            >
              DISC
            </Typography>
          </TransitionLink>
        </LinkElement>
        {links.map((link, idx) => (
          <LinkElement borderColor={borderColor} key={idx} onClick={() => {}}>
            <Typography
              titleType={TypographyTypes.a}
              color={color ? color : Color.Black}
            >
              {/* {link.value} */}
            </Typography>
          </LinkElement>
        ))}
      </LinksContainerElement>
    </NavigationContainerElement>
  )
}
