import { navigate } from "gatsby"
import TransitionLink from "gatsby-plugin-transition-link"
import { Expo, TimelineLite } from "gsap"
import React, { useEffect, useRef, useState } from "react"
import { RoutePath } from "../../../data/RoutePath"
import { Color } from "../../../data/style/variables"
import hamburgerSvg from "../../../images/SVG/hamburger.svg"
import { Bold } from "../Bold/Bold"
import {
  TitleH3,
  Typography,
  TypographyTypes
} from "../Typography/Typography"
import {
  HamburgerElement,
  LinkContainerElement,
  LinkElement,
  MobileSubLinkElement,
  MobileSubLinksElement,
  RotatedLinksElement,
  SideNavigationContainerElement,
  SubLinkContainerElement,
  SubLinkElement,
  SubLinksElement,
} from "./styled"

export type LinkType = {
  value: string
  path: string
}

interface Link {
  value: string
  path?: string
  scrollTo?: string
  subLinks?: SubLink[]
}

interface SubLink {
  value: string
  path: string
}

const timeline = new TimelineLite({ paused: true })

export const SideNavigation: React.FC = () => {
  const subLinkRef = useRef()
  const subLinksRef = useRef()
  const barRef = useRef()
  const [activeLink, setActiveLink] = useState<Link | undefined>()
  const [hamburgerOpen, setHamburgerOpen] = useState(false)

  useEffect(() => {
    timeline
      .fromTo(
        barRef.current,
        0.2,
        {
          background: "transparent",
          ease: Expo.easeOut,
        },
        {
          background: "#EDF1F4",
          ease: Expo.easeOut,
        },
        0.2
      )
      .to(
        subLinkRef.current,
        0.2,
        {
          width: 450,
          borderRight: "solid #D6D9DC 1px",
          ease: Expo.easeOut,
        },
        0.4
      )
      .to(
        subLinksRef.current,
        0.2,
        {
          opacity: 1,
          ease: Expo.easeOut,
        },
        0.8
      )
  }, [])

  const links: Link[] = [
    {
      value: "Home",
      path: RoutePath.Home,
      scrollTo: "home-hero",
    },
    {
      value: "Producten",
      subLinks: [
        { value: "Checklist", path: RoutePath.Checklist },
        { value: "Power Bi", path: RoutePath.PowerBi },
        { value: "Op maat", path: RoutePath.Custom },
        { value: "3D CAD", path: RoutePath.Cad },
        { value: "ERP", path: RoutePath.Erp },
      ],
    },
    {
      value: "Diensten",
      subLinks: [
        { value: "Technische software", path: RoutePath.TechnicalSoftware },
        { value: "Cloud oplossingen", path: RoutePath.Cloud },
        { value: "Web & Apps", path: RoutePath.WebAndApps },
      ],
    },
    {
      value: "Contact",
      scrollTo: "footer-contact",
    },
  ]

  const handleMouseOver = (l: Link) => {
    if (l.subLinks) {
      timeline.play()
      setActiveLink(l)
    } else {
      timeline.reverse()
      setActiveLink(undefined)
    }
  }

  const handleHamburgerClick = () => {
    if (hamburgerOpen) {
      timeline.reverse()
      setHamburgerOpen(false)
    } else {
      timeline.play()
      setHamburgerOpen(true)
    }
  }

  const handleMouseLeave = () => {
    timeline.reverse()
    setActiveLink(undefined)
  }

  const scrollTo = async (l: Link) => {
    const el = document.getElementById(l.scrollTo)

    if (!el && l.path) {
      await navigate(l.path)

      setTimeout(() => {
        const el2 = document.getElementById(l.scrollTo)

        el2.scrollIntoView()
      }, 500)

      return
    }

    el.scrollIntoView()

    setHamburgerOpen(false)
  }

  return (
    <>
      <HamburgerElement
        src={hamburgerSvg}
        onClick={() => handleHamburgerClick()}
      />
      <SideNavigationContainerElement open={hamburgerOpen} ref={barRef}>
        <LinkContainerElement>
          <RotatedLinksElement>
            {links.map(l => (
              <LinkElement
                key={l.value}
                onClick={
                  l.scrollTo !== undefined ? () => scrollTo(l) : () => {}
                }
                onMouseOver={() => handleMouseOver(l)}
              >
                <Typography titleType={TypographyTypes.a} color={Color.Black}>
                  {l.value === activeLink?.value ? <u>{l.value}</u> : l.value}
                </Typography>
                <MobileSubLinksElement>
                  {l.subLinks?.map(s => (
                    <MobileSubLinkElement>
                      <TransitionLink to={s.path}>
                        <Typography
                          titleType={TypographyTypes.a}
                          color={Color.Black}
                          fontSize={"1.6rem"}
                        >
                          <u>{s.value}</u>
                        </Typography>
                      </TransitionLink>
                    </MobileSubLinkElement>
                  ))}
                </MobileSubLinksElement>
              </LinkElement>
            ))}
          </RotatedLinksElement>
        </LinkContainerElement>
        <SubLinkContainerElement
          ref={subLinkRef}
          onMouseLeave={handleMouseLeave}
        >
          <SubLinksElement ref={subLinksRef}>
            <TitleH3 color={Color.Black}>
              <Bold background={Color.Black}>{activeLink?.value}</Bold>
            </TitleH3>
            {activeLink?.subLinks?.map(s => (
              <SubLinkElement>
                <TransitionLink to={s.path}>
                  <Typography
                    titleType={TypographyTypes.a}
                    color={Color.Black}
                    fontSize={"2.3rem"}
                  >
                    <u>{s.value}</u>
                  </Typography>
                </TransitionLink>
              </SubLinkElement>
            ))}
          </SubLinksElement>
        </SubLinkContainerElement>
      </SideNavigationContainerElement>
    </>
  )
}
