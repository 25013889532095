import styled from "styled-components/macro"
import { FontFamily, FontWeight, Color } from "../../../data/style/variables"

type TypographyElementProps = {
  fontFamily?: FontFamily
  fontWeight?: FontWeight
  fontSize?: string
  color?: Color
  lineHeight?: string
  noMargin?: boolean
  underlined?: boolean
  fontSizeMobile?: string
  lineHeightMobile?: string
}

export const H1Element = styled.h1<TypographyElementProps>`
  margin-top: 0;
  margin-bottom: ${props => (props.noMargin ? 0 : "25px")};
  font-family: ${props =>
    props.fontFamily ? props.fontFamily : FontFamily.Roboto};
  font-weight: ${props =>
    props.fontWeight ? props.fontWeight : FontWeight.Regular};

  font-size: ${props => props.fontSize || "6.5rem"};
  line-height: ${props => props.lineHeight || "9.75rem"};

  @media (max-width: 1224px) {
    font-size: ${props => props.fontSizeMobile || "4.5rem"};
    line-height: ${props => props.lineHeightMobile || "normal"};
  }

  color: ${props => (props.color ? props.color : Color.Primary)};
`

export const H2Element = styled.h2<TypographyElementProps>`
  /* margin-top: 0; */
  /* margin-bottom: ${props => (props.noMargin ? 0 : "25px")}; */
  margin: ${props => (props.noMargin ? 0 : `2rem 0 ${props.underlined ? '8' : '3'}rem;`)};
  font-family: ${props =>
    props.fontFamily ? props.fontFamily : FontFamily.Roboto};
  font-weight: ${props =>
    props.fontWeight ? props.fontWeight : FontWeight.Regular};
  color: ${props => (props.color ? props.color : Color.Primary)};

  font-size: ${props => props.fontSize || "6rem"};
  line-height: ${props => props.lineHeight || "9rem"};

  @media (max-width: 1224px) {
    font-size: ${props => props.fontSizeMobile || "2.5rem"};
    line-height: ${props => props.lineHeightMobile || "normal"};
  }

  &::after {
    content: "";
    display: ${props => (props.underlined ? "block" : "none")};
    background: ${props => (props.color ? props.color : Color.Primary)};
    width: 125px;
    height: 5px;
    position: relative;
    bottom: -42px;
  }
`

export const H3Element = styled.h3<TypographyElementProps>`
  margin-top: 0;
  margin-bottom: ${props => (props.noMargin ? 0 : "15px")};
  font-family: ${props =>
    props.fontFamily ? props.fontFamily : FontFamily.Roboto};
  font-weight: ${props =>
    props.fontWeight ? props.fontWeight : FontWeight.Regular};
  color: ${props => (props.color ? props.color : Color.Primary)};

  font-size: ${props => props.fontSize || "2.3rem"};
  line-height: ${props => props.lineHeight || "normal"};

  @media (max-width: 1224px) {
    font-size: ${props => props.fontSizeMobile || "2.5rem"};
    line-height: ${props => props.lineHeightMobile || "normal"};
  }

  margin: 1rem 0 1.5rem;
`

export const H4Element = styled.h2<TypographyElementProps>`
  margin-top: 0;
  margin-bottom: ${props => (props.noMargin ? 0 : "15px")};
  font-family: ${props =>
    props.fontFamily ? props.fontFamily : FontFamily.Roboto};
  font-weight: ${props =>
    props.fontWeight ? props.fontWeight : FontWeight.Regular};
  color: ${props => (props.color ? props.color : Color.Primary)};
  font-size: ${props => (props.fontSize ? props.fontSize : "6rem")};
  line-height: ${props => (props.lineHeight ? props.lineHeight : "normal")};
`

export const PElement = styled.p<TypographyElementProps>`
  font-family: ${props =>
    props.fontFamily ? props.fontFamily : FontFamily.Roboto};
  font-weight: ${props =>
    props.fontWeight ? props.fontWeight : FontWeight.Regular};
  font-size: ${props => (props.fontSize ? props.fontSize : "1.6rem")};
  color: ${props => (props.color ? props.color : Color.Primary)};
  line-height: ${props => (props.lineHeight ? props.lineHeight : "2.8rem")};
  margin-bottom: 10px;
  margin-top: 0;
  margin: ${props => (props.noMargin ? 0 : "0 0 3rem")};
`

export const Span = styled.span<TypographyElementProps>`
  font-family: ${props =>
    props.fontFamily ? props.fontFamily : FontFamily.Roboto};
  font-weight: ${props =>
    props.fontWeight ? props.fontWeight : FontWeight.Regular};
  font-size: ${props => (props.fontSize ? props.fontSize : "1.6rem")};
  color: ${props => (props.color ? props.color : Color.Primary)};
  line-height: ${props => (props.lineHeight ? props.lineHeight : "2.8rem")};
  margin-bottom: 10px;
  margin-top: 0;
`

export const AElement = styled.a<TypographyElementProps>`
  font-family: ${props =>
    props.fontFamily ? props.fontFamily : FontFamily.Roboto};
  font-weight: ${props =>
    props.fontWeight ? props.fontWeight : FontWeight.Regular};
  font-size: ${props => (props.fontSize ? props.fontSize : "1.6rem")};
  line-height: ${props => (props.lineHeight ? props.lineHeight : "normal")};
  color: ${props => (props.color ? props.color : Color.Primary)};
  cursor: pointer;
`
