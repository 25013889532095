import React, { Children } from "react"
import { BoldElement } from "./styled"
import { RevealBlockAnimation } from "../RevealBlockAnimation/RevealBlockAnimation"
import { Color } from "../../../data/style/variables"

type BoldProps = {
  background?: Color;
  reverseOn?: boolean;
  // Will delay based on delay variable inside animation.
  // So index 1, 2, 3: 1 will play then + delay -> 2 will play then + delay -> three will play.
  index?: number;
}

export const Bold: React.FC<BoldProps> = ({ reverseOn, background, index, children }) => {
  return (
    <RevealBlockAnimation index={index} reverseOn={reverseOn} background={background ? background : Color.Black}>
      <BoldElement>{children}</BoldElement>
    </RevealBlockAnimation>
  )
}
